<template>
  <div>
    <b-modal
          :ref="`start-modal`"
          centered
          hide-footer 
          cancel-variant="outline-secondary"
          title-tag="div"
        >
          
 <b-form>
  <b-col
              md="12"
              xl="12"
            >
             
                <b-form-group
                  label-for="startdate"
                >
                  <label> تاريخ بدء الحالة</label>
                  <flat-pickr
                  ref="flatpickr" 
                    v-model="start_date"
                    class="form-control"
                    placeholder="حدد تاريخ البدء"
                    :config="{ static: 'true',  maxDate: max }"
                  />
                </b-form-group>
              
             
            </b-col>
            <hr />
             <b-row>
          <b-col xl="4"></b-col>
          <b-col xl="4" class="d-flex justify-content-center">
            <b-button variant="outline-primary" @click="openCase">
              <span class="align-middle">  بدء الحالة </span>
            </b-button>
          </b-col>
        </b-row>
 </b-form>
        
        </b-modal>
        <b-modal
          :ref="`start-date-modal`"
          centered
          hide-footer 
          cancel-variant="outline-secondary"
          title-tag="div"
        >
          
 <b-form>
  <b-col
              md="12"
              xl="12"
            >
             
                <b-form-group
                  label-for="date"
                >
                  <label> تاريخ بدء الحالة</label>
                  <flat-pickr
                  ref="flatpickr" 
                    v-model="proposing_start_date"
                    class="form-control"
                    placeholder="حدد تاريخ البدء"
                    :config="{ static: 'true',  maxDate: max }"
                  />
                </b-form-group>
              
             
            </b-col>
            <hr />
             <b-row>
          <b-col xl="4"></b-col>
          <b-col xl="4" class="d-flex justify-content-center">
            <b-button variant="outline-primary" @click="saveStartDate">
              <span class="align-middle">  حفظ</span>
            </b-button>
          </b-col>
        </b-row>
 </b-form>
        
        </b-modal>
        <b-modal
          :ref="`close-date-modal`"
          centered
          hide-footer 
          cancel-variant="outline-secondary"
          title-tag="div"
        >
          
 <b-form>
  <b-col
              md="12"
              xl="12"
            >
             
                <b-form-group
                  label-for="date"
                >
                  <label> تاريخ إغلاق الحالة</label>
                  <flat-pickr
                  ref="flatpickr" 
                    v-model="proposing_end_date"
                    class="form-control"
                    placeholder="حدد تاريخ الإغلاق"
                  :config="{ static: 'true',  maxDate: max }"

                  />
                </b-form-group>
              
             
            </b-col>
            <hr />
             <b-row>
          <b-col xl="4"></b-col>
          <b-col xl="4" class="d-flex justify-content-center">
            <b-button variant="outline-primary" @click="saveCloseDate">
              <span class="align-middle">  حفظ</span>
            </b-button>
          </b-col>
        </b-row>
 </b-form>
        
        </b-modal>
    <!-- Filters -->
    <services-list-filters

      :family-status.sync="familyStatus"
      :city.sync="city"
      :town.sync="town"
      :village.sync="village"

      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :service-filter.sync="serviceFilter"
      :main-service.sync="mainService"

      :status-options="statusOptions"
    />
    <b-card no-body>
      <b-card-header class="pb-50" />
      <b-card-body>
        <b-row>
          <b-col
            cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <h6>بحث حسب الاسم او رقم البطاقة</h6>
              <b-form-input
                v-model="search"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                class="w-100"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <hr>

      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ totalUsers }}      عدد العائلات المستفيدة</label>
          <label />

        </b-col>

      </div>
      
      <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="fetcFamilies"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column::items="fetcFamilies" User primary-key="id" -->

        <!-- Column: Role -->

        <!-- Column: another number
         -->

        <!-- Column: Status -->
        <template #cell(proposing_end_date)="data">
          <p 
          v-if="data.item.proposing_end_date != null" >
          {{data.item.proposing_end_date}}
          <feather-icon @click="showClosingModal(data.item.id)" icon="EditIcon" class="mr-50" />

          </p>
          <p 
          v-if="data.item.proposing_end_date == null && data.item.service_status == 'مغلقة'" >
          <b-button
            variant="outline-primary"
            @click="showClosingModal(data.item.id)"
          >
          <span > تحديد تاريخ الإغلاق</span>
          </b-button>
          </p>
          
         
          
        </template>
        <template #cell(proposing_start_date)="data">
          <p 
          v-if="data.item.proposing_start_date != null" >
          {{data.item.proposing_start_date}}
          <feather-icon  @click="showStartingModal(data.item.id)" icon="EditIcon" class="mr-50" />

          </p>
          <p 
          v-if="data.item.proposing_start_date == null && (data.item.service_status == 'مفتوحة' || data.item.service_status == 'مغلقة')" >
          <b-button
            variant="outline-primary"
            @click="showStartingModal(data.item.id)"
          >
          <span> تحديد تاريخ البدء</span>
          </b-button>
          </p>
          
         
          
        </template>
        <template #cell(service_status)="data">

          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.service_status)}`"
            class="text-capitalize"
          >
            {{ data.item.service_status }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              :to="{
                name: 'service-committe-report',
                params: { id: data.item.person_id },
              }"
              v-if="data.item.service_status == 'قيد الانتظار'"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تقرير اللجنة</span>
            </b-dropdown-item> -->
            <b-dropdown-item-button
              v-if="data.item.service_status == 'حالة جديدة من قسم التسجيل'"
              @click="requestStatus(data.item.id)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">طلب بدء الحالة </span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="role == 'information_manager' && data.item.service_status == 'قيد الانتظار'"
              @click="changeStatus(data.item.id,1)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تغيير الحالة</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="role == 'information_manager' && data.item.service_status == 'مفتوحة'"
              @click="changeStatus(data.item.id,2)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تغيير الحالة</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="role == 'information_manager' && data.item.service_status == 'مغلقة'"
              @click="changeStatus(data.item.id,0)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تغيير الحالة</span>
            </b-dropdown-item-button>
            <b-dropdown-item
              v-if="data.item.service_status == 'مفتوحة'"
              :to="{ name: 'show-service-report', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">متابعة الحالة</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'service-transfer', params: { id: data.item.id } }"
            >
              <feather-icon icon="ArrowLeftIcon" />
              <span class="align-middle ml-50"> تحويل داخلي</span>
            </b-dropdown-item>

            <b-dropdown-item-button
              v-if="data.item.service_status == 'قيد الانتظار'"
              @click="openStartModal(data.item.id)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">بدء الحالة</span>
            </b-dropdown-item-button>

            <b-dropdown-item
              v-if="data.item.service_status != 'قيد الانتظار'"
              @click="succesStory(data.item.id)"
            >
              <feather-icon icon="StarIcon" />
              <span class="align-middle ml-50">قصة نجاح </span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="data.item.service_status != 'قيد الانتظار'"
              :to="{
                name: 'show-family-data',
                params: { id: data.item.family_id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50"> عرض خدمات العائلة </span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <hr>

      <!-- <b-row>
        <b-col xl="4"></b-col>
        <b-col xl="3">
          <b-button @click="download" variant="purple" class="shadow">
       <vue-excel-xlsx
              ref="childComponent"
              :data="datatest"
              :columns="columns"
              :file-name="filename"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
              class="button-excel"
            >
              <span> Export To Excel</span>
             </vue-excel-xlsx>
          </b-button>
        </b-col>
      </b-row> -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BModal,
  BFormInvalidFeedback,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { max } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { jsontoexcel } from 'vue-table-to-excel'
import ServicesListFilters from './ServicesListFilters.vue'
import useServicesList from './useServicesList'
import servicesStoreModule from './servicesStoreModule'
import store from '@/store'
import FamilyDetailsVue from '../families/family-beneficiaries/Family-Details.vue'

export default {
  setup() {
    const Services_APP_STORE_MODULE_NAME = 'app-services'

    // Register module
    if (!store.hasModule(Services_APP_STORE_MODULE_NAME)) { store.registerModule(Services_APP_STORE_MODULE_NAME, servicesStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Services_APP_STORE_MODULE_NAME)) { store.unregisterModule(Services_APP_STORE_MODULE_NAME) }
    })

    const genderOptions = [
      { label: 'ذكر', value: 1 },
      { label: 'أنثى', value: 2 },
      { label: ' غير محدد ', value: 0 },
    ]

    const statusOptions = [
      { label: 'بالأنتظار', value: 0 },
      { label: 'مفتوحة', value: 1 },
      { label: 'مغلقة', value: 2 },
      { label: 'حالة جديدة من قسم التسجيل ', value: 4 },
      { label: 'بانتظار الموافقة', value: 5 },
      { label: 'تم تحويلها', value: 6 },
    ]
    //  const serviceOptions = [
    //   { label: "Pending", value: 0 },
    //   { label: "Opened", value: 1 },
    //   { label: "Closed", value: 2 },

    // ];
    const dataFound = ref(FamilyDetailsVue)
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())

    const {
      fetchBenServices,

      tableColumns,
      fetcFamilies,
      perPage,
      page,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,
      ExcelDone,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      gender,
      familyStatus,
      town,
      village,
      weakness,
      city,
      search,
      min_age,
      max_age,
      startDate,
      fileExcel,
      endDate,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      openStatus,
      changeStatus,
      requestStatus,

      succesStory,
      datatest,
    } = useServicesList()

    return {
      // Sidebar
      fetcFamilies,
      dataFound,
      datatest,
      ExcelDone,
      fetchBenServices,
      changeStatus,
      requestStatus,
      openStatus,
      succesStory,
      tableColumns,
      perPage,
      page,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // Filter
      search,
      min_age,
      max_age,
      avatarText,
      serviceOptions: [],
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      genderOptions,

      statusOptions,

      // Extra Filters
      fileExcel,
      startDate,
      endDate,
      town,
      village,
      weakness,
      city,
      gender,
      familyStatus,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      max: maxDate,
    }
  },
  components: {
    BModal,
    ServicesListFilters,
    flatPickr,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,

  },
  data() {
    return {
      start_date:"",
      requestId:"",
      proposing_end_date:"",
      proposing_start_date:"",
      role: '',
      fields: [
        { key: 'relation', label: 'صلة القرابى' },
        { key: 'number', label: 'الرقم' },
        { key: 'notes', label: 'الملاحظات' },
      ],

      items: [],
      columns: [
        {
          label: 'الرقم',
          field: 'id',

        },
        {
          label: 'الاسم',
          field: 'first_name',
        },
        {
          label: 'النسبة',
          field: 'last_name',
        },
        {
          label: 'الجنس',
          field: 'gender',
        },
        {
          label: 'رقم الهاتف',
          field: 'phone_numbers',
        },

        {
          label: 'الخدمة',
          field: 'service',
        },
        {
          label: 'حالة الخدمة',
          field: 'service_status',
        },
        {
          label: 'تاريخ الميلاد',
          field: 'birth_date',
        },

        {
          label: 'تاريخ التسجيل',
          field: 'registration_date',
        },
        {
          label: 'نقاط الضعف',
          field: 'weakness',
        },
        {
          label: ' المدينة',
          field: 'city',
        },
        {
          label: 'الحي',
          field: 'town',
        },
        {
          label: 'طبيعة العائلة',
          field: 'family_status',
        },
      ],
      filename: '',
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const queryParams = JSON.parse(localStorage.getItem('queryParams'))
  //  console.log(queryParams)
    this.gender = queryParams.value.gender
    this.familyStatus = queryParams.value.familyStatus
    this.city = queryParams.value.city
    this.town = queryParams.value.neighborhood
    this.village = queryParams.value.town
    this.planFilter = queryParams.value.planFilter
    this.statusFilter = queryParams.value.service_status
    // this.serviceFilter=queryParams.value.service
    this.mainService = queryParams.value.main_service
    this.min_age = queryParams.value.min_age
    this.max_age = queryParams.value.max_age
    this.startDate = queryParams.value.maxDate
    this.endDate = queryParams.value.minDate
    this.role = userData.roles[0].name
    this.perPage = queryParams.value.page_size
    this.page = queryParams.value.page
    this.search = queryParams.value.search

  },
  methods: {
    showModal(id) {
      // console.log(id)
      this.$refs[`my-modal-${id}`].show()
    },
    showNumber() {
      this.$refs.childComponent.show()
    },
    openStartModal(id) {
      this.requestId= id,
      this.$refs["start-modal"].show();
    },
    
    showClosingModal(id) {
      this.requestId= id,
      this.$refs["close-date-modal"].show();
    },
    showStartingModal(id) {
      this.requestId= id,
      this.$refs["start-date-modal"].show();
    },
    saveCloseDate(){
      
      this.$http.put(`/api/v1/service_management/${this.requestId}/case_date?proposing_end_date=${this.proposing_end_date}`)
      .then((res) => {
        this.requestId=''
        this.proposing_end_date= ''
        
        this.refetchData()
        this.$refs["close-date-modal"].hide();
        this.$swal({
          title: "",
          text: "تمت إضافة تاريخ الإغلاق بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        
      });
    },
        saveStartDate(){
      
      this.$http.put(`/api/v1/service_management/${this.requestId}/case_date?proposing_start_date=${this.proposing_start_date}`)
      .then((res) => {
        this.requestId=''
        this.proposing_start_date= ''
        this.refetchData()
        this.$refs["start-date-modal"].hide();
        this.$swal({
          title: "",
          text: "تمت إضافة تاريخ البدء بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        
      });
    },

    openCase(){
//   console.log(this.caseId + this.note)
   let data = {start_date: this.start_date, id: this.requestId}
  this.openStatus(data) 
    this.start_date= ''
    this.$refs["start-modal"].hide()
  
    },
    async download() {
      await this.fileExcel().then(() => {
        // console.log(this.datatest);

        this.$refs.childComponent.exportExcel()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}

div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
